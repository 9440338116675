import "./styles.css";
import Typography from "@mui/material/Typography";
import { TargetInstance } from "hooks/useGlobalIndex/types";
import { memo } from "react";
import CopyIcon from "components/CopyIcon";
import { Fade, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import handleTxIds from "utils/handleTxIds";
type TablesProps = {
  data: any;
};

const Tables = (props: TablesProps) => {
  const { data } = props;
  const [, setSearchParams] = useSearchParams();
  console.log("data", data);
  return (
    <div>
      {data.tables && (data.tables.map((table:any) => (
        <div
          style={{
            width: "100%",
            backgroundColor: "#20214a",
            borderRadius: "6px",
            marginBottom: "20px",
          }}
          className="grid_container"
        >
          <div className="grid_cell" style={{ margin: "10px 0px" }}>
            <Typography className="BoldWhiteFont14" align="left">
              Table Alias
            </Typography>
            <div className="L2TxId-row">
              <Typography className="BoldFont14Hover" align="center">
                {table?.alias}
              </Typography>
            </div>
          </div>
        </div>)
      ))}
    </div>
  );
};

export default memo(Tables);
